pre {
  font-family: 'courier new', courier, monospace;
  font-size: 11px;
}

.fullscreen-fixed {
  height: 100%;  /* Fill the window */
  width: 100vw;
  padding:0;
  margin:0;
  position: fixed;
  top:0;
  right:0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.scroll {
  -ms-overflow-style: none;
}

.scroll-show::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll-show::-webkit-scrollbar:vertical {
  width: 0px;
}

.scroll-show::-webkit-scrollbar:horizontal {
  height: 9px;
}

.scroll-show::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.scroll-show::-webkit-scrollbar-track {
  border-radius: 10px;  
  background-color: #ffffff; 
}

.formcontrol::label {
  font-size: 12px;
}

.on-top {
  z-index: 1500;
}

.on-bottom {
  z-index: -1;
}

.loading-light {
  background:rgba(255,255,255,0.65);
}

.loading-dark {
  background:rgba(0,0,0,0.65);
}

.hide {
  display: none !important;
}

.responsive {
  width: 80%;
  max-width: 250px;
  height: auto;
}

.red-text {
  color: red;
}

.pointer-events-auto {
  pointer-events: auto;
}

.touch-action-none {
  touch-action: pinch-zoom;
}

.pointer-events-none {
  pointer-events: none;
}

.dragger {
  width: 5px;
  cursor: ew-resize;
  padding: 4px 0 0;
  height: 90vh;
  z-index: 100;
}

.drawer {
  background-color: '#274052'
}

.pdf-viewer {
  min-width: 90vw;
  min-height: 100%;
}

.tracking-lock {
  transform: rotate(315deg);
}

.disconnected-status {
  color: #cc0000;
  width: 10px;
  height: 10px;
  margin: 0px !important;
  margin-right: 7.5px !important;
  margin-left: 7.5px !important;
}

.connected-status {
  color: #43a047;
  width: 10px;
  height: 10px;
  margin: 0px !important;
  margin-right: 7.5px !important;
  margin-left: 7.5px !important;
}

.connecting-status {
  color: #cc0000;
  width: 10px;
  height: 10px;
  margin: 0px !important;
  margin-right: 7.5px !important;
  margin-left: 7.5px !important;
}

.Blink {
  animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}
@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  display: flex;
  flex: 1;
  min-height: 70px;
}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

