
.drawcontainer {
    position: absolute;
    z-index: 10;
    padding: 20px;
    flex-direction: row;
}

.drawselected {
    background-color: #e2e0e0;
}
